import { createApp } from 'vue'
import App from './App.vue'
import router from './router/router'
import store from './store'
import {sync} from 'vuex-router-sync'
import './assets/css/main.scss'
import 'vuetify/styles'
import 'aos/dist/aos.css'
import "vue3-json-viewer/dist/index.css";
import '@mdi/font/css/materialdesignicons.css'
import ModalCadastro from './components/ModalCadastro'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import {createVuetify} from 'vuetify'
import PForm from "./components/PForm";
import TableIterator from "./components/TableIterator";
import VConfirm from "./components/VConfirm";
import VueTheMask  from 'vue-the-mask'
import Alerta from "./components/Alerta";
import money3 from 'v-money3'

const vuetify = createVuetify({
   components,
   directives,
})


const app = createApp(App)
sync(store, router)
app.use(money3)

app.use(router)
app.use(vuetify)
app.use(store)
app.use(VueTheMask)
app.component('v-confirm', VConfirm)
app.component('pro-modal', ModalCadastro)
app.component('p-form', PForm)
app.component('pro-alerta', Alerta)
app.component('table-iterator', TableIterator)
app.mount('#app')
