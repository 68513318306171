<template>
  <v-app id="app">
    <v-main class="backmain" >
      <router-view/>
    </v-main>
  </v-app>
</template>
<script>
  export default {
    name: 'App',
  }
</script>
