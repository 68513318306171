<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
			<div>
						<v-snackbar v-model="Mostrar" :timeout="3500" location="top" :color="cor">
									<span style="color: white; font-size: 16px">{{ texto }}</span>
									<template v-slot:actions>
												<v-icon aria-label="close"  @click="fechar" color="white">mdi-close</v-icon>
									</template>
						</v-snackbar>

			</div>
</template>

<script>
   /* eslint-disable */
   export default {
      name: 'Alerta',
      props: {
         texto: {
            type: String,
            default: 'Texto'
         },
         cor: {
            type: String,
            default: 'red'
         },
         timeout: {
            type: Number,
            default: 3500
         },
         show: {
            type: Boolean,
            default: false
         },
      },
      data() {
         return {
            snackbar: false
         }
      },
      methods: {
         abreModal() {
         },
         fechar() {
            this.$emit('abriAlerta', false)
         },
      },
      computed: {
         Mostrar: {
            get() {
               return this.show
            },
            set() {
               return this.Mostrar
            }
         }
      }
   }
</script>
